import React from "react";

const Service = () => {
  return (
    <div className="service">
      <div className="container-fluid">
        <div className="section-header">
          <h2>Our Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec pretium ornare velit non
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Web Design</h3>
              <img src="img/icon-service-1.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Development</h3>
              <img src="img/icon-service-2.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>UI Design</h3>
              <img src="img/icon-service-3.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Programming</h3>
              <img src="img/icon-service-4.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Graphic Design</h3>
              <img src="img/icon-service-5.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Video Editing</h3>
              <img src="img/icon-service-6.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>SEO</h3>
              <img src="img/icon-service-7.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-item">
              <h3>Online Marketing</h3>
              <img src="img/icon-service-8.png" alt="Service" />
              <p>Lorem ipsum dolor sit amet elit pretium ornare</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
