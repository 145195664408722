import React from "react";

const Story = () => {
  return (
    <div className="story">
      <div className="container-fluid">
        <div className="section-header">
          <h2>Our Story</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec pretium ornare velit non
          </p>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="story-container">
              <div className="story-end">
                <p>Now</p>
              </div>
              <div className="story-continue">
                <div className="row story-right">
                  <div className="col-md-6">
                    <p className="story-date">01 Jun 2020</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon">
                        <i className="fa fa-gift" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row story-left">
                  <div className="col-md-6 d-md-none d-block">
                    <p className="story-date">01 Jan 2020</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon d-md-none d-block">
                        <i className="fa fa-business-time" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                      <div className="story-icon d-md-block d-none">
                        <i className="fa fa-business-time" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none">
                    <p className="story-date">01 Jan 2020</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="story-year">
                      <p>2020</p>
                    </div>
                  </div>
                </div>
                <div className="row story-right">
                  <div className="col-md-6">
                    <p className="story-date">01 Dec 2019</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon">
                        <i className="fa fa-briefcase" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row story-left">
                  <div className="col-md-6 d-md-none d-block">
                    <p className="story-date">01 Sep 2019</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon d-md-none d-block">
                        <i className="fa fa-cogs" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                      <div className="story-icon d-md-block d-none">
                        <i className="fa fa-cogs" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none">
                    <p className="story-date">01 Sep 2019</p>
                  </div>
                </div>
                <div className="row story-right">
                  <div className="col-md-6">
                    <p className="story-date">01 Jun 2019</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon">
                        <i className="fa fa-running" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row story-left">
                  <div className="col-md-6 d-md-none d-block">
                    <p className="story-date">01 Mar 2019</p>
                  </div>
                  <div className="col-md-6">
                    <div className="story-box">
                      <div className="story-icon d-md-none d-block">
                        <i className="fa fa-home" />
                      </div>
                      <div className="story-text">
                        <h3>Lorem ipsum dolor</h3>
                        <p>Lorem ipsum dolor sit amet elit ornare velit non</p>
                      </div>
                      <div className="story-icon d-md-block d-none">
                        <i className="fa fa-home" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-md-block d-none">
                    <p className="story-date">01 Mar 2019</p>
                  </div>
                </div>
              </div>
              <div className="story-start">
                <p>Launch</p>
              </div>
              <div className="story-launch">
                <div className="story-box">
                  <div className="story-text">
                    <h3>Launched our company on 01 Jan 2019</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
