import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div id="video-section">
              <div className="youtube-player">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Wtroop739uU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>{" "}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h2 className="section-title">Learn About Us</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              nec pretium mi. Curabitur facilisis ornare velit non vulputate.
              Aliquam metus tortor, auctor id gravida condimentum, viverra quis
              sem. Curabitur non nisl nec nisi scelerisque maximus. Aenean
              consectetur convallis porttitor. Aliquam interdum at lacus non
              blandit.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              nec pretium mi. Curabitur facilisis ornare velit non vulputate.
              Aliquam metus tortor, auctor id gravida condimentum, viverra quis
              sem. Curabitur non nisl nec nisi scelerisque maximus. Aenean
              consectetur convallis porttitor. Aliquam interdum at lacus non
              blandit.
            </p>
            <a className="btn" href="">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
