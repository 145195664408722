import React from "react";

const Header = () => {
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="navbar navbar-expand-lg bg-light navbar-light">
              <a href="#" className="navbar-brand">
                MENU
              </a>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav">
                  <a href="index.html" className="nav-item nav-link">
                    Home
                  </a>
                  <a href="about.html" className="nav-item nav-link">
                    About Us
                  </a>
                  <a href="services.html" className="nav-item nav-link">
                    Services
                  </a>
                  <a href="portfolio.html" className="nav-item nav-link">
                    Portfolio
                  </a>
                  <a href="single.html" className="nav-item nav-link">
                    Single Page
                  </a>
                  <a href="contact.html" className="nav-item nav-link">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
