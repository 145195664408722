import React from "react";
import FaqImg from "../../assets/img/faqs.jpg";

const Faqs = () => {
  return (
    <div className="faqs">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="section-title">Frequently Asked Questions</h2>
            <div id="accordion">
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link collapsed"
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                  >
                    Lorem ipsum dolor sit amet?
                  </a>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec pretium mi. Curabitur facilisis ornare velit
                    non.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href="#collapseTwo"
                  >
                    Lorem ipsum dolor sit amet?
                  </a>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec pretium mi. Curabitur facilisis ornare velit
                    non.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href="#collapseThree"
                  >
                    Lorem ipsum dolor sit amet?
                  </a>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec pretium mi. Curabitur facilisis ornare velit
                    non.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href="#collapseFour"
                  >
                    Lorem ipsum dolor sit amet?
                  </a>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus nec pretium mi. Curabitur facilisis ornare velit
                    non.
                  </div>
                </div>
              </div>
            </div>
            <a className="btn" href="">
              Ask more
            </a>
          </div>
          <div className="col-md-6">
            <img src={FaqImg} alt="Faq.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
