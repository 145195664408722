import React from "react";
import Slider from "react-slick";
import testimonial1 from "../../assets/img/testimonial-1.jpg";
import testimonial2 from "../../assets/img/testimonial-2.jpg";
import testimonial3 from "../../assets/img/testimonial-3.jpg";
import testimonial4 from "../../assets/img/testimonial-4.jpg";

const Testimonial = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="testimonial">
      <div className="container">
        <div className="section-header">
          <h2>Clients Review</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec pretium ornare velit non
          </p>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              <div
                className="slider-nav"
                style={{ position: "relative", height: "160px" }}
              >
                <img src={testimonial1} alt="Testimonial1.jpg" />
                <h3>Customer Name</h3>
                <h4>profession</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                  vulputate. Aliquam metus tortor, auctor id gravida
                  condimentum, viverra quis sem. Curabitur non nisl nec nisi
                  scelerisque maximus.
                </p>
              </div>
              <div
                className="slider-nav"
                style={{ position: "relative", height: "160px" }}
              >
                <img src={testimonial2} alt="Testimonial2.jpg" />
                <h3>Customer Name</h3>
                <h4>profession</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                  vulputate. Aliquam metus tortor, auctor id gravida
                  condimentum, viverra quis sem. Curabitur non nisl nec nisi
                  scelerisque maximus.
                </p>
              </div>
              <div
                className="slider-nav"
                style={{ position: "relative", height: "160px" }}
              >
                <img src={testimonial3} alt="Testimonial3.jpg" />
                <h3>Customer Name</h3>
                <h4>profession</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                  vulputate. Aliquam metus tortor, auctor id gravida
                  condimentum, viverra quis sem. Curabitur non nisl nec nisi
                  scelerisque maximus.
                </p>
              </div>
              <div
                className="slider-nav"
                style={{ position: "relative", height: "160px" }}
              >
                <img src={testimonial4} alt="Testimonial4.jpg" />
                <h3>Customer Name</h3>
                <h4>profession</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                  vulputate. Aliquam metus tortor, auctor id gravida
                  condimentum, viverra quis sem. Curabitur non nisl nec nisi
                  scelerisque maximus.
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
