import React from "react";
import team1 from "../../assets/img/team-1.jpg";
import team2 from "../../assets/img/team-2.jpg";
import team3 from "../../assets/img/team-3.jpg";
import team4 from "../../assets/img/team-4.jpg";

const Team = () => {
  return (
    <div className="team">
      <div className="container-fluid">
        <div className="section-header">
          <h2>Our Team</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            nec pretium ornare velit non
          </p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <div className="team-img">
                <img src={team1} alt="Team1.jpg" />
              </div>
              <div className="team-text">
                <h3>Member Name</h3>
                <p>Designation</p>
              </div>
              <div className="team-social">
                <a href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="">
                  <i className="fab fa-twitter" />
                </a>
                <a href="">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="">
                  <i className="fab fa-pinterest" />
                </a>
                <a href="">
                  <i className="fab fa-youtube" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <div className="team-img">
                <img src={team2} alt="Team2.jpg" />
              </div>
              <div className="team-text">
                <h3>Member Name</h3>
                <p>Designation</p>
              </div>
              <div className="team-social">
                <a href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="">
                  <i className="fab fa-twitter" />
                </a>
                <a href="">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="">
                  <i className="fab fa-pinterest" />
                </a>
                <a href="">
                  <i className="fab fa-youtube" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <div className="team-img">
                <img src={team3} alt="Team3.jpg" />
              </div>
              <div className="team-text">
                <h3>Member Name</h3>
                <p>Designation</p>
              </div>
              <div className="team-social">
                <a href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="">
                  <i className="fab fa-twitter" />
                </a>
                <a href="">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="">
                  <i className="fab fa-pinterest" />
                </a>
                <a href="">
                  <i className="fab fa-youtube" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <div className="team-img">
                <img src={team4} alt="Team4.jpg" />
              </div>
              <div className="team-text">
                <h3>Member Name</h3>
                <p>Designation</p>
              </div>
              <div className="team-social">
                <a href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="">
                  <i className="fab fa-twitter" />
                </a>
                <a href="">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="">
                  <i className="fab fa-pinterest" />
                </a>
                <a href="">
                  <i className="fab fa-youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
