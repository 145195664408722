import React from "react";
import HammerImg from "../assets/img/hammer.png";
import Metatags from "../components/Metatags/Metatags";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import Service from "../components/Service/Service";
import Faqs from "../components/Faqs/Faqs";
import Testimonial from "../components/Testimonial/Testimonial";
import Story from "../components/Story/Story";
import Team from "../components/Team/Team";
import Footer from "../components/Footer/Footer";

import "../assets/css/font.css";
import "../assets/css/index.css";
import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";
import "../assets/css/lightbox.min.css";
import "../assets/css/bootstrap.min.css";

import "./full.css";

const HomePage = () => {
  return (
    <>
      <Metatags
        title="Justice Journey"
        description="Justice Journey will be launched soon. Stay tuned for more updates..!!!"
        type="website"
        url="https://justicejourney.in/"
        image={HammerImg}
      />
      <div className="wrapper">
        <Header />
        <Hero />
        <About />
        <Service />
        <Faqs />
        <Testimonial />
        <Story />
        <Team />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
